// MODULES
import React from "react";

import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// COMPONENTS
import Modal from "@components/Modal/Modal";
import ContentBox from "@components/ContentBox/ContentBox";

// CUSTOM COMPONENTS
import CustomerActionsButton from "@cobComponents/CustomerActionsButton";
import CustomerMenuDrawer from "./CustomerMenuDrawer";
import CustomerInformationCard from "./CustomerInformationCard";
import LastSignedCard from "./LastSignedCard";
import AdvisorsCard from "./AdvisorsCard";
import PoaCard from "./PoaCard";
import ContactDetailsCard from "./ContactDetailsCard";
import UploadAndSignCard from "./UploadAndSignCard";
import BankDetailsCard from "./BankDetailsCard";

import DashboardSessions from "../../views/Dashboard/DashboardSessions";
import DashboardPoas from "../../views/Dashboard/DashboardPoas";
import DashboardFutur from "../../views/Dashboard/DashboardFutur";
import CustomerNotes from "./CustomerNotes";

// HELPERS
// import i18n from "@helpers/i18n";

import useCustomerModal from "./useCustomerModal";
import LogsTable from "./LogsTable";

const CustomerModal = props => {
  const { partner, isMobileOrTablet, isVisible, 
    user, advisor, client, views, currentView, isSession, handleClose, handleViewChange, updateCustomers, caseFilterContext, addCustomerModalOpen, toggleAddCustomerModal, setCustomerState, nextStepOptions, setSelectedCustomer, notes, setNotes} = useCustomerModal(props);

  return (
    <Modal
      className="Cob-CustomersTable-CustomerModal"
      visible={isVisible}
      title={client.name}
      subtitle={client.id}
      onClose={handleClose}
    >
      {!isSession && (
        <Box
          sx={{
            position: "absolute",
            top: "30px",
            right: "100px",
            height: "32px",
            width: "200px",
            color: "#FFF"
          }}
        >
          <CustomerActionsButton
            advisor={advisor}
            client={client}
            partner={partner}
            nextStepOptions={nextStepOptions}
            setSelectedCustomer={setSelectedCustomer}
            setCustomerState={setCustomerState}
            toggleAddCustomerModal={toggleAddCustomerModal}
            cobForm={caseFilterContext?.cobForm}
          />
        </Box>
      )}
      <CloseIcon
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "15px",
          right: "15px",
          height: "32px",
          width: "32px",
          color: "#FFF",
          backgroundColor: "grey.400",
          borderRadius: "50%",
          padding: "5px",
          cursor: "pointer"
        }}
      />
      {isMobileOrTablet && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            marginBottom: { xs: 2, md: 4 },
            borderBottom: "5px solid #003d50"
          }}
        >
          <Tabs
            variant="scrollable"
            aria-label="customer tabs"
            value={currentView}
            onChange={handleViewChange}
            scrollButtons={false}
            // allowScrollButtonsMobile
            sx={{
              minHeight: "unset",
              "& .MuiTabs-flexContainer": {
                flexDirection: "row"
              },
              "& .MuiButtonBase-root": {
                fontFamily: "Roboto",
                fontSize: "16px",
                minHeight: "unset",
                py: 0.625,
                px: 2.25,
                color: "#FFF",
                backgroundColor: "#003d50",
                border: 0,
                borderBottom: "5px solid #003d50"
                // transform: "skew(10deg)" /* Equal to skewX(10deg) */
              },
              "& .MuiTabs-scrollButtons.Mui-disabled": {
                opacity: 0.3
              },
              "& .Mui-selected": {
                // color: "#FFF !important",
                color: "#003d50 !important",
                // backgroundColor: "#003d50",
                backgroundColor: "#FFF",
                border: "3px solid #003d50",
                borderBottom: "4px solid #003d50"
              },
              "& .Mui-disabled": {
                backgroundColor: "rgba(0, 63, 82, 0.25)",
                borderBottom: "rgba(0, 63, 82, 0.25)",
                cursor: "not-allowed !important",
                pointerEvents: "auto !important"
              },
              "& .MuiTabs-indicator": {
                display: "none"
              },
              "& .MuiTabs-scroller": {
                alignSelf: "flex-start",
                width: "-webkit-fill-available"
              }
            }}
          >
            {views.map((item, index) => (
              <Tab
                key={index}
                disableRipple
                disableFocusRipple
                label={item.label}
                value={item.name}
                sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
              />
            ))}
          </Tabs>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isMobileOrTablet ? "column" : "row",
          alignItems: "flex-start"
        }}
      >
        {!isMobileOrTablet && (
          <CustomerMenuDrawer
            menuItems={views}
            currentView={currentView}
            totalNotes={notes && notes.length}
            onToggle={() => null}
            handleViewChange={handleViewChange}
          />
        )}
        {currentView === "overview" && (
          <ContentBox
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 18,
              alignItems: "flex-start",
              width: "100%",
              minHeight: "66vh",
              maxHeight: "78vh",
              margin: 0,
              padding: 0,
              boxShadow: "none",
              overflow: "auto"
            }}
          >
            {partner.customerModalWidgets &&
              partner.customerModalWidgets.map((widget, index) => {
                switch (widget) {
                  case "CustomerInformation":
                    return (
                      <CustomerInformationCard
                        key={index}
                        client={client}
                        partner={partner}
                        advisor={advisor}
                        toggleAddCustomerModal={toggleAddCustomerModal}
                        cobForm={caseFilterContext?.cobForm}
                        setCustomerState={setCustomerState}
                        handleModalClose={handleClose}
                      />
                    );
                  case "LastSigned":
                    return <LastSignedCard key={index} client={client} />;
                  case "Advice":
                    return (
                      <AdvisorsCard
                        key={index}
                        client={client}
                        addCustomerModalOpen={addCustomerModalOpen}
                      />
                    );
                  case "Poa":
                    return (
                      <PoaCard key={index} client={client} advisor={advisor} />
                    );
                  case "ContactDetails":
                    return (
                      <ContactDetailsCard
                        key={index}
                        client={client}
                        updateCustomers={updateCustomers}
                      />
                    );
                  case "UploadAndSign":
                    return (
                      <UploadAndSignCard
                        key={index}
                        advisor={advisor}
                        client={client}
                      />
                    );
                  case "BankDetails":
                    return (
                      <BankDetailsCard
                        key={index}
                        advisor={advisor}
                        client={client}
                        standaloneApplications={partner.standaloneApplications}
                      />
                    );
                  default:
                    return null;
                }
              })}
          </ContentBox>
        )}
        {currentView === "sessions" && (
          <DashboardSessions
            type="customerModal"
            user={user}
            advisor={advisor}
            partner={partner}
            client={client}
            cobForm={caseFilterContext.cobForm}
            caseFilter={caseFilterContext.caseFilter || "draft"}
            setCaseFilter={caseFilterContext.setCaseFilter}
            setCustomerState={setCustomerState}
          />
        )}
        {currentView === "futur" && (
          <DashboardFutur
            type="customerModal"
            user={user}
            advisor={advisor}
            partner={partner}
            client={client}
            cobForm={caseFilterContext.cobForm}
            caseFilter={caseFilterContext.caseFilter || "sent"}
            setCaseFilter={caseFilterContext.setCaseFilter}
          />
        )}
        {currentView === "poa" && (
          <DashboardPoas
            type="customerModal"
            user={user}
            advisor={advisor}
            partner={partner}
            client={client}
            cobForm={caseFilterContext.cobForm}
            caseFilter={caseFilterContext.caseFilter || "sent"}
            setCaseFilter={caseFilterContext.setCaseFilter}
          />
        )}
        {currentView === "notes" && (
          <CustomerNotes
            user={user}
            client={client}
            notes={notes}
            setNotes={setNotes}
            updateCustomers={updateCustomers}
          />
        )}
        {currentView === "logs" && <LogsTable client={client} />}
      </Box>
    </Modal>
  );
};

export default CustomerModal;
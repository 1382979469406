import {mergeViewStateForFulfillment} from "../../../../Cob.helpers";
import {_getInitialState} from "./AddonPlacement";

export default function (state, prevState) {
  // if no props are passed just return valid (initial render fix)
  if (!this.props) {
    return true;
  }

	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") {
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	let {customerState} = state;

	/**
	 * To support including this component and having the fulfillment work correctly in the progress bar we check
	 * if customerstate has been set. If not, we re-init it using the values in the customer object.
	 */
	if (customerState === undefined) {
		customerState = _getInitialState(this.props).customerState;
	}

	/**
	 * At least one of the products needs to be selected
	 */
	if (!customerState.radio_placement_spets &&
		!customerState.radio_placement_guru &&
		!customerState.radio_placement_other &&
		!customerState.radio_allocation_fee_spets &&
		!customerState.radio_allocation_fee_guru &&
		!customerState.radio_allocation_fee_other) {
		return false;
	}

	if (customerState.radio_placement_spets.length === 0 &&
		customerState.radio_placement_guru.length === 0 &&
		customerState.radio_placement_other.length === 0) {
		return false;
	}

	/**
	 * Tilläggsplacering spets
	 */
	if (customerState.radio_placement_spets) {

		if (customerState.customer_spets_depa.length === 0 || customerState.customer_spets_depa.length > 9) {
			return false;
		}

		if (customerState.depositedAmount > 0 && (
			customerState.allocation_amount_spets.length === 0 ||
			customerState.allocation_amount_spets === "0" ||
			customerState.allocation_amount_spets === 0 ||
			parseFloat(customerState.allocation_amount_spets.replaceAll(" ", "")) <= 0
		)) {
			return false;
		}
	}

	/**
	 * If Spets has been chosen all additional fields need to be entered
	 */
	if (customerState.radio_placement_spets || customerState.radio_allocation_fee_spets) {
		if (customerState.radio_allocation_risk_spets.length <= 0 ||
			customerState.checkbox_accounttype_spets.length <= 0 ||
			customerState.radio_allocation_perc_spets.length <= 0) {
			return false;
		}

		if (!(customerState.radio_allocation_fee_spets === "reduced" || customerState.radio_allocation_fee_spets))
		{
			// Performance fee + Förvaltningsavgift
			if (customerState.allocation_fee_spets_revenueshare.length === 0 ||
				customerState.allocation_fee_spets_rebate.length === 0) {
				return false;
			}
		}
		else
		{
			if (customerState.aprx_value_invested.length <= 0)
			{
				return false;
			}
		}
	}

	/**
	 * Tilläggsplacering guru
	 */
	if (customerState.radio_placement_guru) {
		if (customerState.customer_guru_depa.length === 0 || customerState.customer_guru_depa.length > 9) {
			return false;
		}

		if (customerState.depositedAmount > 0 && (
			customerState.allocation_amount_guru.length === 0 ||
			customerState.allocation_amount_guru === "0" ||
			customerState.allocation_amount_guru === 0 ||
			parseFloat(customerState.allocation_amount_guru.replaceAll(" ", "")) <= 0
		)) {
			return false;
		}
	}

	/**
	 * If Guru has been chosen all additional fields need to be entered
	 */
	if (customerState.radio_placement_guru || customerState.radio_allocation_fee_guru) {
		if (customerState.radio_allocation_risk_guru.length <= 0 ||
			customerState.checkbox_accounttype_guru.length <= 0 ||
			customerState.radio_allocation_perc_guru.length <= 0) {
			return false;
		}

		if (!(customerState.radio_allocation_fee_guru === "reduced" || customerState.radio_allocation_fee_guru))
		{
			// Performance fee + Förvaltningsavgift
			if (customerState.allocation_fee_guru_revenueshare.length <= 0 ||
				customerState.allocation_fee_guru_rebate.length <= 0) {
				return false;
			}
		}
		else
		{
			if (customerState.aprx_value_invested.length <= 0)
			{
				return false;
			}
		}
	}

	/**
	 * Tilläggsplacering Fair Ädelmetaller
	 */
	if (customerState.radio_placement_other) {
		if (customerState.customer_other_depa.length === 0 || customerState.customer_other_depa.length > 9) {
			return false;
		}

		if (customerState.depositedAmount > 0 && (
			customerState.allocation_amount_other.length === 0 ||
			customerState.allocation_amount_other === "0" ||
			customerState.allocation_amount_other === 0 ||
			parseFloat(customerState.allocation_amount_other.replaceAll(" ", "")) <= 0
		)) {
			return false;
		}
	}

	/**
	 * Om man valt att ändra avgift för spets ska alla spetsfält fyllas i
	 */
	if (customerState.radio_allocation_fee_spets) {
		if (customerState.assets_spets_depa.length === 0 || customerState.assets_spets_depa.length > 9) {
			return false;
		}

		if (customerState.previous_fee_spets.length === 0 ||
			customerState.previous_fee_spets < 0 ||
			customerState.previous_fee_spets > 100) {
			return false;
		}

		if (customerState.allocation_fee_spets_revenueshare_new.length === 0 ||
			customerState.allocation_fee_spets_revenueshare_new < 0 ||
			customerState.allocation_fee_spets_revenueshare_new > 100) {
			return false;
		}

		if (customerState.onetime_fee_spets.length === 0 ||
			customerState.onetime_fee_spets < 0 ||
			customerState.onetime_fee_spets > 100) {
			return false;
		}

		if (customerState.future_onetime_fee_spets.length === 0 ||
			customerState.future_onetime_fee_spets < 0 ||
			customerState.future_onetime_fee_spets > 100) {
			return false;
		}
	}

	/**
	 * Om man valt att ändra avgift för guru ska alla gurufält fyllas i
	 */
	if (customerState.radio_allocation_fee_guru) {
		if (customerState.assets_guru_depa.length === 0 || customerState.assets_guru_depa.length > 9) {
			return false;
		}

		if (customerState.previous_fee_guru.length === 0 ||
			customerState.previous_fee_guru < 0 ||
			customerState.previous_fee_guru > 100) {
			return false;
		}

		if (customerState.allocation_fee_guru_revenueshare_new.length === 0 ||
			customerState.allocation_fee_guru_revenueshare_new < 0 ||
			customerState.allocation_fee_guru_revenueshare_new > 100) {
			return false;
		}

		if (customerState.onetime_fee_guru.length === 0 ||
			customerState.onetime_fee_guru < 0 ||
			customerState.onetime_fee_guru > 100) {
			return false;
		}

		if (customerState.future_onetime_fee_guru.length === 0 ||
			customerState.future_onetime_fee_guru < 0 ||
			customerState.future_onetime_fee_guru > 100) {
			return false;
		}
	}

	/**
	 * Om man valt att ändra avgift för fair ädelmetaller ska alla ädelmetallfält fyllas i
	 */
	if (customerState.radio_allocation_fee_other) {
		if (customerState.assets_other_depa.length === 0 || customerState.assets_other_depa.length > 9) {
			return false;
		}

		if (customerState.previous_fee_other.length === 0 ||
			customerState.previous_fee_other < 0 ||
			customerState.previous_fee_other > 100) {
			return false;
		}

		if (customerState.allocation_fee_other_revenueshare_new.length === 0 ||
			customerState.allocation_fee_other_revenueshare_new < 0 ||
			customerState.allocation_fee_other_revenueshare_new > 100) {
			return false;
		}

		if (customerState.onetime_fee_other.length === 0 ||
			customerState.onetime_fee_other < 0 ||
			customerState.onetime_fee_other > 100) {
			return false;
		}

		if (customerState.future_onetime_fee_other.length === 0 ||
			customerState.future_onetime_fee_other < 0 ||
			customerState.future_onetime_fee_other > 100) {
			return false;
		}
	}

	/**
	 * Man måste kryssa i om kunden förstått produkterna
	 */
	if (customerState.checkbox_advice_customer.length === 0) {
		return false;
	}

	/**
	 * Investment hidden fields need to be entered
	 */
	if (customerState.investment_product.length === 0 ||
		customerState.investment_amount.length === 0 ||
		customerState.investment_share_of_assets.length === 0 ||
		customerState.investment_time_horizon.length === 0 ||
		customerState.investment_risk_class.length === 0 ||
		customerState.investment_account_type.length === 0 ||
		customerState.investment_fee_revshare.length === 0 ||
		customerState.investment_fee_rebate.length === 0 ||
		customerState.investment_fee_explained.length === 0 ||
		customerState.investment_fee_other.length === 0 ||
		customerState.investment_fee_other_sek.length === 0) {
		return false;
	}

	return true;
}